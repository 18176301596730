import React, {useState, useEffect} from 'react';
import MaterialTable from 'material-table';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next'

function createData(name, surname, username, email) {
  return { name, surname, username, email };
}

function UserPanelView(props) {
  const { t, i18n } = useTranslation()
  const columns = [
      {title: t("nameLabel"), field: 'name'},
      {title: t("surnameLabel"), field: 'surname'},
      {title: t("usernameLabel"), field: 'username'},
      {title: t("emailLabel"), field: 'email'},
  ];
  var [data, setData] = useState([]);
  const submission = {
      title: 'This is title',
      body: 'This is body',
      userId: 1
  };
  useEffect(() => {
    function getUsers(_callback) {
      fetch('http://gusmeroligabriele.altervista.org/db/get_users_2.php', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: localStorage.getItem("username")
        })
      })
      .then(response => response.json())
      .then(responseJson => {
                  const results = [];
                  var count = responseJson["length"];
                  var i = 0;
                  while(i < count){
                    results.push(
                      createData(responseJson[i], responseJson[i+1], responseJson[i+2], responseJson[i+3])
                    );
                    i = i + 4;
                  }
                  setData(results);
                  _callback();
              })
              .catch(error => {
                  console.error(error.toString());
              });
      }

      getUsers(function () {
          console.log("finish");
      });

  }, []);
  return (
    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
      <div style={{height: '100%', width: '100%'}}>
          {
              data ?
              <div style={{height: '100%'}}>
              <MaterialTable
              title=""
              columns={columns}
              data={data}
              options={{
                search: true,
                paging: true,
                maxBodyHeight: '100%',
                headerStyle: { position: 'sticky', top: 0 },
                searchFieldAlignment: 'left',
                exportButton: true,
                exportCsv: true
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        fetch('http://gusmeroligabriele.altervista.org/db/modify_client.php', {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                          },
                          body: JSON.stringify({
                            name: Object.values(newData)[0],
                            surname: Object.values(newData)[1],
                            newUser: Object.values(newData)[2],
                            newEmail: Object.values(newData)[3],
                            oldUser: Object.values(oldData)[2],
                            oldEmail: Object.values(oldData)[3],
                          })
                        })
                        .then(response => response.json())
                        .then(responseJson => {
                        })
                        .catch(error => {
                            console.error(error.toString());
                        });
                      }
                    }, 600);
                  }),
                  onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      fetch('http://gusmeroligabriele.altervista.org/db/delete_client.php', {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                          },
                          body: JSON.stringify({
                            username: Object.values(oldData)[2],
                          })
                        })
                        .then(response => response.json())
                        .then(responseJson => {
                          if(responseJson == "ok"){
                            props.history.push({
                              pathname: '/users',
                              state: ""
                          });  
                          }
                        })
                        .catch(error => {
                            console.error(error.toString());
                        });
                    }, 600);
                  }),
              }}
            /></div>: null
          }
      </div>
    </div>
  );
}

export default withRouter(UserPanelView);