import React, {useState, useEffect} from 'react';
import MaterialTable from 'material-table';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next'

function createData(name, description, time, category) {
  return { name, description, time, category };
}

function ExercisePanelView(props) {
  const { t, i18n } = useTranslation()
  const columns = [
      {title: t("nameLabel"), field: 'name'},
      {title: t("descriptionLabel"), field: 'description'},
      {title: t("timeLabel"), field: 'time'},
      {title: t("categoryLabel"), field: 'category'},
  ];
  var [data, setData] = useState([]);
  const submission = {
      title: 'This is title',
      body: 'This is body',
      userId: 1
  };
  useEffect(() => {
    function getUsers(_callback) {
      fetch('http://gusmeroligabriele.altervista.org/db/get_exercises.php', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: localStorage.getItem("username")
        })
      })
      .then(response => response.json())
      .then(responseJson => {
                  const results = [];
                  var count = responseJson["length"];
                  var i = 0;
                  while(i < count){
                    results.push(
                      createData(responseJson[i], responseJson[i+1], responseJson[i+2], responseJson[i+3])
                    );
                    i = i + 4;
                  }
                  setData(results);
                  _callback();
              })
              .catch(error => {
                  console.error(error.toString());
              });
      }

      getUsers(function () {
      });

  }, []);
  return (
    <div>
          {
              data ?
                <MaterialTable
                  title=""
                  columns={columns}
                  data={data}
                  options={{
                    search: true,
                    paging: true,
                    headerStyle: { position: 'sticky', top: 0 },
                    searchFieldAlignment: 'left',
                    columnResizable: true,
                    exportButton: true,
                    exportCsv: true,
                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          if (oldData) {
                            fetch('http://gusmeroligabriele.altervista.org/db/modify_client.php', {
                              method: "POST",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                              },
                              body: JSON.stringify({
                                name: Object.values(newData)[0],
                                surname: Object.values(newData)[1],
                                newUser: Object.values(newData)[2],
                                newEmail: Object.values(newData)[3],
                                oldUser: Object.values(oldData)[2],
                                oldEmail: Object.values(oldData)[3],
                              })
                            })
                            .then(response => response.json())
                            .then(responseJson => {
                            })
                            .catch(error => {
                                console.error(error.toString());
                            });
                          }
                        }, 600);
                      }),
                      onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          fetch('http://gusmeroligabriele.altervista.org/db/delete_client.php', {
                              method: "POST",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                              },
                              body: JSON.stringify({
                                username: Object.values(oldData)[2],
                              })
                            })
                            .then(response => response.json())
                            .then(responseJson => {
                              if(responseJson == "ok"){
                                var nUsers = localStorage.getItem("nUsers");
                                nUsers++;
                                localStorage.setItem("nUsers", nUsers);
                              }
                            })
                            .catch(error => {
                                console.error(error.toString());
                            });
                        }, 600);
                      }),
                  }}
              />: null
          }
      </div>
  );
}

export default withRouter(ExercisePanelView);