import React, {useState} from 'react';
import { withRouter } from 'react-router-dom';
import {TextField, Button, Snackbar, makeStyles} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab/'
import MuiAlert from '@material-ui/lab/Alert';
import MuscleCategory from '../../Components/MuscleCategory/MuscleCategory'

import { Trans, useTranslation } from 'react-i18next'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));






const ExercisePanelAdd = (props) => {
    const [name, setName] = useState('');
    const [maxTime, setMaxTime] = useState('');
    const [muscleGroup, setMuscleGroup] = useState('');
    const [description, setDescription] = useState('');

    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
    
    const { t, i18n } = useTranslation()

    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };
    //populateMuscleCombo();
    const classes = useStyles();
    return (
        <div style={{margin: 'auto', display: 'flex', flexDirection: 'column', justyifyContent: 'center', alignItems: 'center'}}><br /><br />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <TextField id="standard-secondary" label={t('nameFieldLabel')} color="primary" variant="outlined" value={name}
                            onChange={(val) => {
                                setName(val.target.value);
                            }}
                />
                <TextField id="time" label={t('maxTimeLabel')} type="time" defaultValue="00:00" variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(val) => {
                        setMaxTime(val.target.value);
                    }}
                    style={{width: '200px'}}
                />
                {/* <Autocomplete id="standard-secondary" options={muscleCategories} getOptionLabel={(option) => option.title} value={muscleGroup}
                                renderInput={(params) => <TextField {...params} label={t('muscolarGroupFieldLabel')} variant="outlined" />}
                                onChange={(val) => {
                                setMuscleGroup(val.target.value);
                                }}
                                style={{ width: 300 }}
                /> */}
                <MuscleCategory />
            </div><br />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <TextField id="description" label={t('descriptionFieldLabel')} color="primary" multiline rows={6} variant="outlined" style={{width: '100%'}} value={description}
                            onChange={(val) => {
                            setDescription(val.target.value);
                            }}
                />
            </div><br /><br />
            <div>
                <Button variant="outlined" color="primary" onClick={CreateExercise()}>
                    {t('addButtonLabel')}
                </Button>
            </div>
        </div>
    );

    function populateMuscleCombo(){
        fetch('http://gusmeroligabriele.altervista.org/db/muscle_combo.php', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        })
        
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
            }).catch((error) => {
                console.error(error);
            });
    }

    function populateMuscleCombo(){
        var arrayy = new Array();
        arrayy.push('CIao');
    }

    function CreateExercise ()
    {
        fetch('http://gusmeroligabriele.altervista.org/db/new_exercise.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                maxTime: maxTime,
                muscleGroup: muscleGroup,
                description: description,
            })
            
            }).then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson == true){
                        setName('');
                        setMaxTime('');
                        setMuscleGroup('');
                        setDescription('');     
                    }
                }).catch((error) => {
                    console.error(error);
                });
    }
}

const style = {
    margin: 20,
    color: 'white',
   }; 

export default withRouter(ExercisePanelAdd);