import React from 'react';
import { withRouter } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../Components/RegisterCard/RegisterCard.css';

class RegisterGym extends React.Component {
    constructor(props){
        super(props);
        this.state={
        name:'',
        address:'',
        gymName:'Select your gym',
        button:'' //if 1 it register new gym if 2 gym already exist
        }
    }

    render(){
        return(
        <div style={{alignItems: 'center', justifyContent: 'center'}}>
            <div className="registerDiv" style={{display: 'flex', flexDirection: 'column',margin: 'auto', marginTop: '10%'}}>
                <div className="descri">
                <li><h1>BIG GYM</h1></li>
                <br/>
                <li style={{fontSize: '18px'}}>REGISTER YOUR GYM</li>
                <div>
                    <MuiThemeProvider>
                    <div className="objects">
                        <p>
                            <TextField
                                hintText="Enter gym name"
                                floatingLabelText="Gym name"
                                value={this.state.name}
                                onChange = {(event,newValue) => this.setState({name:newValue})}
                                />
                        </p>
                        <p>
                            <TextField
                            hintText="Enter gym address"
                            floatingLabelText="Gym address"
                            onChange = {(event,newValue) => this.setState({address:newValue})}
                                />
                        </p>
                        <br/>
                      
                            <RaisedButton label="Register gym" primary={true} style={style} onClick={this.GymRegistrationFunction}/>
                     
                    </div>
                    <li style={{fontSize: '18px'}}>OR<br />SELECT WHERE YOU ARE WORKING AT</li><br />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.gymName}
                        onChange = {(event,newValue) => this.setState({gymName:newValue})}
                        >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    <br/><RaisedButton label="Complete sign up" primary={true} style={style} onClick={this.ConnectGymOwner}/>
                    </MuiThemeProvider>
                </div>
        
                </div>
            </div>
        </div>
        );
    }

    ConnectGymOwner = (props) =>{

    }

    GymRegistrationFunction = (props) =>{
        if(this.checkFields()){
            fetch('http://gusmeroligabriele.altervista.org/db/new_gym.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                address: this.state.address,
            })
            
            }).then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson[0] !== "err"){
                        fetch('http://gusmeroligabriele.altervista.org/db/connect_g_o.php', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                owner: localStorage.getItem("idOwn"),
                                gym: responseJson[1],
                            })
                            
                            }).then((response) => response.json())
                                .then((responseJson) => {
                                    if(responseJson == "ok"){
                                        localStorage.setItem("gymName", this.state.name);
                                        localStorage.setItem("address", this.state.address);
                                        this.props.history.push({
                                            pathname: '/home',
                                            state: ""
                                        });   
                                    }   
                                }).catch((error) => {
                                    console.error(error);
                                });
                    } else if(responseJson == "err"){
                        alert("Gym's already registered check it again!");
                    }       
                }).catch((error) => {
                    console.error(error);
                });
        
        }
    }

    checkFields = (props) =>{
        let err = 0;
        var errore = "Miss: ";
        if(this.state.name == ""){
            err--;
            errore = errore + "Name ";
        }
        if(this.state.address == ""){
            err--;
            errore = errore + "Address ";
        }
        if(err != 0){
            alert(errore);
            return false;
        }
        return true;
    }

}

const style = {
    margin: 20,
   };  

export default withRouter(RegisterGym);

/*
this.props.history.push({
                            pathname: '/home',
                            state: ""
                        });   
*/