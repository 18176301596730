import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    preload: ['it', 'en'],
    load: 'currentOnly',
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
    }
  }, (err, t) => {
    console.log(t("header.signup"))
  })

export default i18n

console.log(i18n.t("header.signup"))