import React from 'react';
import {Link} from "react-router";
import {Button} from "@material-ui/core";
import './ContactsCard.css';

class ContactsCard extends React.Component {

    render(){
        return(
        <div style={{alignItems: 'center', justifyContent: 'center'}}>
            <div className="round" style={{display: 'flex', flexDirection: 'column',margin: 'auto', marginTop: '10%'}}>
                <div className="desc">
                    <li><h1>BIG GYM</h1></li>
                    <br/>
                    <li><h3>Hi</h3></li>
                    <li><h4>What can we help you with?</h4></li>
                    <li><h4>Write us for everything you wanna know, write to:<br/><br/><font color="#f50057">gusmeroligabriele22@gmail.com</font></h4></li>
                </div>
            </div>
        </div>
        );
    }
}

export default ContactsCard
