import React from "react";
import Toolbar from "../../Components/Toolbar/Toolbar";
import Backdrop from "../../Components/Backdrop/Backdrop";
import SideDrawer from "../../Components/SideDrawer/SideDrawer";
import "./MainPage.css";

export class MainPage extends React.Component {
    state = {
    sideDrawerOpen: false,
    };

    drawerToggleClickHandler = () => {
        this.setState(prevState => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    };

    backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
    };
    
    render() {
        let backdrop
        let sideDrawer

        if (this.state.sideDrawerOpen) {
        backdrop = <Backdrop click={this.backdropClickHandler} />
        sideDrawer = <SideDrawer />
        }
        return (
            <div style={{width: '100%', height: '100%', position: 'fixed', display: 'flex', flexDirection: 'column'}}>
                <div  style={{zIndex: '5'}}>
                    <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
                </div>
                <SideDrawer show={this.state.sideDrawerOpen} />
                {backdrop}
                <div id='particles' style={{top: '0', left: '0', width: '100%', height: '100%', position: 'fixed', zIndex: '4'}}>
                    <div className="childrenComp" style={{backgroundColor: '#151719', top: '50px', left: '0', width: '100%', height: '100%', position: 'fixed', alignItems: 'center', justifyContent: 'center', textAlign: 'center', overflow: 'hidden'}}>
                        {this.props.children}
                    </div>
                </div>
            </div>  
        );
    }
}