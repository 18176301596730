import React from 'react';
import { withRouter } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import './LoginCard.css';

class LoginCard extends React.Component {
    constructor(props){
        super();
        this.state = {
            username: '',
            password: '',
        };
    }

    render(){
        return(
        <div style={{alignItems: 'center', justifyContent: 'center'}}>
            <div className="roundedDiv" style={{display: 'flex', flexDirection: 'column',margin: 'auto', marginTop: '10%'}}>
                <div className="descriptione">
                <li><h1>BIG GYM</h1></li>
                <br/>
                <li>Login</li>
                <div>
                    <MuiThemeProvider>
                    <div>
                    
                    <TextField
                        hintText="Enter your Username"
                        floatingLabelText="Username"
                        onChange = {(event,newValue) => this.setState({username:newValue})}
                        />
                    <br/>
                        <TextField
                        type="password"
                        hintText="Enter your Password"
                        floatingLabelText="Password"
                        onChange = {(event,newValue) => this.setState({password:newValue})}
                        />
                        <br/>
                            <RaisedButton label="Sign in" primary={true} style={style} onClick={this.UserLoginFunction}/>
                    </div>
                    </MuiThemeProvider>
                </div>
                </div>
            </div>
        </div>
        );
    }

    UserLoginFunction = (props) =>{
        fetch('http://gusmeroligabriele.altervista.org/db/user_login.php', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              password: this.state.password,
              username: this.state.username,
          })
        
        }).then((response) => response.json())
              .then((responseJson) => {
                if(responseJson == "ok"){
                    localStorage.setItem("name", "");
                    localStorage.setItem("surname", "");
                    localStorage.setItem("email", "");
                    localStorage.setItem("username", "");
                    localStorage.setItem("nUsers", "0");
                    fetch('http://gusmeroligabriele.altervista.org/db/getData.php', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            password: this.state.password,
                            username: this.state.username,
                        })
                        
                        }).then((response) => response.json())
                            .then((responseJson) => {
                                //name, surname, email, username, gym_name, gym_address, numero_utenti
                                localStorage.setItem("name", responseJson[0]);
                                localStorage.setItem("surname", responseJson[1]);
                                localStorage.setItem("email", responseJson[2]);
                                localStorage.setItem("username", responseJson[3]);
                                localStorage.setItem("gymName", responseJson[4]);
                                localStorage.setItem("address", responseJson[5]);
                                localStorage.setItem("nUsers", parseInt(responseJson[6]));
                                this.props.history.push({
                                    pathname: '/home',
                                    state: ""
                                });
                            }).catch((error) => {
                                console.error(error);
                            });
                   
                } else{
                    alert("Invalid username/Wrong password!");
                }
              }).catch((error) => {
                console.error(error);
              });
       
      }
}

const style = {
    margin: 20,
   };  

export default withRouter(LoginCard);
