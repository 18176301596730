
import React from 'react';
import { withRouter } from 'react-router-dom';
import {TextField, Button, Card} from '@material-ui/core'

const SheetPanelAdd = (props) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justyifyContent: 'center', alignItems: 'center'}}>
            <br />
                    <Card style={{width: '100%', height: '100%'}}>
                    <div style={{margin: 'auto', display: 'flex', flexDirection: 'column', justyifyContent: 'center', alignItems: 'center'}}>
                        <div><h2>INSERT USER'S DATA</h2></div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <p style={{marginRight: '10px'}}>
                                <TextField
                                    id="Name"
                                    label="Enter your name"
                                    defaultValue=""
                                    helperText=""
                                    variant="outlined"
                                    onChange = {(event,newValue) => this.setState({name:newValue})}
                                />
                            </p>
                            <p style={{marginLeft: '10px'}}>
                                <TextField
                                label="Enter your Surname"
                                floatingLabelText="Surname"
                                onChange = {(event,newValue) => this.setState({surname:newValue})}
                                    />
                            </p>
                        </div>
                        <p>
                            <TextField
                            label="Enter your Username"
                            floatingLabelText="Username"
                            onChange = {(event,newValue) => this.setState({username:newValue})}
                                />
                        </p>
                        <div  style={{display: 'flex', flexDirection: 'row'}}>
                            <p style={{marginRight: '10px'}}>
                                <TextField
                                    label="Enter your Email"
                                    floatingLabelText="Email"
                                    onChange = {(event,newValue) => this.setState({email:newValue})}
                                    />
                            </p>
                            <p style={{marginLeft: '10px'}}>
                                <TextField
                                    type="password"
                                    label="Enter your Password"
                                    floatingLabelText="Password"
                                    onChange = {(event,newValue) => this.setState({password:newValue})}
                                    />
                            </p>                        
                        </div>
                        <Button label="Sign up" color="primary" variant="outlined" size="large"/>
                    </div>
                    </Card>
                <br /><br />
            </div>
        </div>
    );
}

    // UserRegistrationFunction = (props) =>{
    //     if(this.checkFields()){
    //         fetch('http://gusmeroligabriele.altervista.org/db/new_user.php', {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             name: this.state.name,
    //             surname: this.state.surname,
    //             email: this.state.email,
    //             password: this.state.password,
    //             username: this.state.username,
    //             ownUser: localStorage.getItem("username")
    //         })
            
    //         }).then((response) => response.json())
    //             .then((responseJson) => {
    //                 if(responseJson == "ok"){
    //                     localStorage.setItem("nUsers", parseInt(localStorage.getItem("nUsers")) + 1);
    //                     this.props.history.push({
    //                         pathname: '/users',
    //                         state: ""
    //                     });   
    //                 } else if(responseJson == "error1"){
    //                     alert("Registered email, check if it's correct!");
    //                 }else if(responseJson == "error2"){
    //                     alert("Username already taken!");
    //                 }else if(responseJson == "error3"){
    //                     alert("Username already taken!\nRegistered email, check if it's correct!");
    //                 }          
    //             }).catch((error) => {
    //                 console.error(error);
    //             });
        
    //     }
    // }

    // checkFields = (props) =>{
    //     let err = 0;
    //     var errore = "Miss: ";
    //     if(this.state.name == ""){
    //         err--;
    //         errore = errore + "Name ";
    //     }
    //     if(this.state.surname == ""){
    //         err--;
    //         errore = errore + "Surname ";
    //     }
    //     if(this.state.username == ""){
    //         err--;
    //         errore = errore + "Username  ";
    //     }
    //     if(this.state.email == ""){
    //         err--;
    //         errore = errore + "Email ";
    //     }
    //     if(this.state.password == ""){
    //         err--;
    //         errore = errore + "Password  ";
    //     }
    //     if(err != 0){
    //         alert(errore);
    //         return false;
    //     }
    //     return true;
    // }

const style = {
    margin: 20,
    color: 'white',
   }; 

   export default withRouter(SheetPanelAdd);