import React, {useState} from 'react';
import { withRouter } from 'react-router-dom';
import {TextField, Button, makeStyles} from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../Components/Notification/snackbar";

const UserPanelAdd = (props) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [date, setDate] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [route, setRoute] = useState('');
    const [healthProblems, sethealthProblems] = useState('');

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation()

    const setDispatch = (type, message) => {
        dispatch(
            setSnackbar(
              true,
              type,
              message
            )
         )
    }

    const UserRegistrationFunction = (props) => {
        console.log("CIAO");
        fetch('http://gusmeroligabriele.altervista.org/db/new_user.php', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: name,
            surname: surname,
            username: username,
            email: email,
            password: password,
            date: date,
            phoneNumber: phoneNumber,
            city: city,
            route: route,
            healthProblems: healthProblems,
            ownUser: localStorage.getItem("username")
        })
        
        }).then((response) => response.json())
            .then((responseJson) => {
                if(responseJson == "ok"){
                    localStorage.setItem("nUsers", parseInt(localStorage.getItem("nUsers")) + 1); 
                    setDispatch("success", "User Registrated Succesfully!") 
                } else if(responseJson == "error1"){
                    setDispatch("error", "Registered email, check if it's correct!") 
                }else if(responseJson == "error2"){
                    setDispatch("error", "Username already taken!") 
                }else if(responseJson == "error3"){
                    setDispatch("error", "Username already taken!\nRegistered email, check if it's correct!") 
                }          
            }).catch((error) => {
                console.error(error);
            });
    }

    return (
        <>
        <div style={{margin: 'auto', display: 'flex', flexDirection: 'column', justyifyContent: 'center', alignItems: 'center'}}><br /><br />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <TextField id="standard-secondary" label={t('nameFieldLabel')} color="primary" variant="outlined" value={name}
                            onChange={(val) => {
                            setName(val.target.value);
                            }}
                />
                <TextField id="standard-secondary" label={t('surnameLabel')} color="primary" variant="outlined" value={surname}
                            onChange={(val) => {
                            setSurname(val.target.value);
                            }}
                />
                <TextField id="standard-secondary" label={t('usernameLabel')} color="primary" variant="outlined" value={username}
                            onChange={(val) => {
                            setUsername(val.target.value);
                            }}
                />
                <TextField id="date" label="Birthday" type="date" defaultValue="2020-10-22" variant="outlined" value={date}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(val) => {
                                setDate(val.target.value);
                            }}
                />
            </div><br />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <TextField id="standard-secondary" label="Cellulare" color="primary" variant="outlined" value={phoneNumber}
                            onChange={(val) => {
                            setPhoneNumber(val.target.value);
                            }}
                />
                <TextField id="standard-secondary" label="Citta" color="primary" variant="outlined" value={city}
                            onChange={(val) => {
                            setCity(val.target.value);
                            }}
                />
                <TextField id="standard-secondary" label="Via" color="primary" variant="outlined" value={route}
                            onChange={(val) => {
                            setRoute(val.target.value);
                            }}
                            style={{width: '350px'}}
                />
            </div><br />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <TextField id="standard-secondary" label="Email" color="primary" variant="outlined" value={email}
                            onChange={(val) => {
                            setEmail(val.target.value);
                            }}
                />
                <TextField id="standard-secondary" label="Confirm Email" color="primary" variant="outlined" value={confirmEmail}
                            onChange={(val) => {
                            setConfirmEmail(val.target.value);
                            }}
                />
                <TextField id="standard-secondary" label="Password" color="primary" variant="outlined" value={password}
                            onChange={(val) => {
                            setPassword(val.target.value);
                            }}
                />
                <TextField id="standard-secondary" label="Confirm Password" color="primary" variant="outlined" value={confirmPassword}
                            onChange={(val) => {
                            setConfirmPassword(val.target.value);
                            }}
                />
            </div><br />
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <TextField id="description" label={t('descriptionFieldLabel')} color="primary" multiline rows={6} variant="outlined" style={{width: '100%'}} value={healthProblems}
                            onChange={(val) => {
                            sethealthProblems(val.target.value);
                            }}
                />
            </div><br />
        <div>
            <Button variant="outlined" color="primary" onClick={UserRegistrationFunction} >
                {t('addButtonLabel')}
            </Button>
        </div>
    </div>
    </>
    );

    

    // function checkFields() {
    //     let err = 0;
    //     var errore = "Miss: ";
    //     if(this.state.name == ""){
    //         err--;
    //         errore = errore + "Name ";
    //     }
    //     if(this.state.surname == ""){
    //         err--;
    //         errore = errore + "Surname ";
    //     }
    //     if(this.state.username == ""){
    //         err--;
    //         errore = errore + "Username  ";
    //     }
    //     if(this.state.email == ""){
    //         err--;
    //         errore = errore + "Email ";
    //     }
    //     if(this.state.password == ""){
    //         err--;
    //         errore = errore + "Password  ";
    //     }
    //     if(err != 0){
    //         alert(errore);
    //         return false;
    //     }
    //     return true;
    // }
}

const style = {
    margin: 20,
    color: 'white',
   }; 

   export default withRouter(UserPanelAdd);