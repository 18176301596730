import React from 'react';
import {Link} from "react-router-dom";
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css'

const Toolbar = props => (
  <header className="toolbar" style={{backgroundColor: 'black'}}>
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className="toolbar__logo">
        <Link to={"/"}>
        <div className="icon">
            <i className="fab fa-react"></i>
          </div>
          <span><h6>BIG GYM</h6></span>
        </Link>
      </div>
      <div className="spacer" />
      <div className="toolbar_navigation-items">
        <ul>
          <li>
            <Link to={"contacts"}>CONTACT</Link>
          </li>
          <li>
            <Link to={"register"}>REGISTER</Link>
          </li>
          <li>
            <Link to={"login"}>LOGIN</Link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
)

export default Toolbar