import React, {useState, useEffect} from 'react';
import MaterialTable from 'material-table';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next'

function createData(name, surname, username, email) {
  return { name, surname, username, email };
}

function SheetPanelView(props) {
  const { t, i18n } = useTranslation()
  const columns = [
      {title: t("nameLabel"), field: 'name'},
      {title: t("descriptionLabel"), field: 'description'},
      {title: t("timeLabel"), field: 'time'},
      {title: t("categoryLabel"), field: 'category'},
  ];
  var [data, setData] = useState([]);
  const submission = {
      title: 'This is title',
      body: 'This is body',
      userId: 1
  };
  useEffect(() => {
    function getUsers(_callback) {
      fetch('http://gusmeroligabriele.altervista.org/db/get_users_2.php', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: localStorage.getItem("username")
        })
      })
      .then(response => response.json())
      .then(responseJson => {
                  const results = [];
                  var count = responseJson["length"];
                  var i = 0;
                  while(i < count){
                    results.push(
                      createData(responseJson[i], responseJson[i+1], responseJson[i+2], responseJson[i+3])
                    );
                    i = i + 4;
                  }
                  setData(results);
                  _callback();
              })
              .catch(error => {
                  console.error(error.toString());
              });
      }

      getUsers(function () {
          console.log("finish");
      });

  }, []);
  return (
    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
    <br />
      <div style={{height: '80%'}}>
          {
              data ?
              <div style={{height: '100%'}}>
              <MaterialTable
              title=""
              columns={columns}
              data={data}
              options={{
                search: true,
                paging: true,
                maxBodyHeight: '100%',
                headerStyle: { position: 'sticky', top: 0 },
                searchFieldAlignment: 'left',
              }}
              style={{height: '100%', overflowY: 'scroll'}}
            /></div>: null
          }
      </div>
      </div>
  );
}

export default withRouter(SheetPanelView);