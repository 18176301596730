import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import store from "./redux/configureStore";
import * as serviceWorker from './serviceWorker';
import './i18n'

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <CssBaseline />
          <App />
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>,
  rootElement
);
serviceWorker.unregister();