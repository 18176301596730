import React from 'react';
import './Components/DashBoard/DashBoard.css';
import { Link, withRouter} from "react-router-dom";
import { Button } from '@material-ui/core';

class SettingsDelete extends React.Component {
    constructor(props){
        super();
        this.state = {
            username: '',
            password: '',
        };
    }

    render(){
        return (
            <div style={{width: '100%', height: '85%',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div><h1>SETTINGS</h1></div>
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', margin: 'auto'}}>
                        <div style={{width: '100%', height: '10%', disaply: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{color: 'darkRed'}}><h2>ARE YOU SURE TO DELETE YOUR ACCOUNT?</h2></p>
                        </div>
                        <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
                            <Button variant="contained" color="secondary" size="large" style={{width: '200px'}}
                            onClick={this.deleteAccount}
                            
                            >YES</Button>
                            <Link to={"/settings"}><Button variant="contained" color="tertiary" size="large" style={{width: '200px'}}>no, just kidding</Button></Link>
                        </div>
                    </div>
            </div>
        );
    };
    
deleteAccount = (props) =>{
    fetch('http://gusmeroligabriele.altervista.org/db/delete_user.php', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: localStorage.getItem("username"),
        })
        
        }).then((response) => response.json())
            .then((responseJson) => {
                if(responseJson == "ok"){
                    this.props.history.push({
                        pathname: '/',
                        state: ""
                    });
                }
            }).catch((error) => {
                console.error(error);
            })
}
}


export default withRouter(SettingsDelete);