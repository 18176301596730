import React from 'react';
import './DashBoard.css';
import {Link} from "react-router-dom";
import {Card, Button} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next'
import LineGraph from 'react-line-graph'

const DashBoard = () => {
    const { t, i18n } = useTranslation()
    const data = [10,0,-2.5,1040,400,250,600];

    const props = {
        data,
        smoothing: 0.4,
        accent: 'palevioletred',
        fillBelow: 'rgba(200,67,23,0.1)',
        hover: true,
        debug: true,
    };

    return (
        <div style={{width: '100%', height: '85%',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div><h1>{localStorage.getItem("gymName")}</h1></div>
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', margin: 'auto'}}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
                        <Card className="infoCard" style={{width: '350px', height: '250px'}}>
                            <div>{t("customerslabel")}</div>
                            <LineGraph {...props}/>
                            {/* <div>
                                <h2>{localStorage.getItem("nUsers") == null ? 0 : localStorage.getItem("nUsers") }</h2>
                            </div> */}
                        </Card>
                        <Card className="infoCard" style={{width: '350px', height: '250px'}}>
                            <div>{t("sheetslabel")}</div>
                                <LineGraph {...props}/>
                        </Card>
                        <Card className="infoCard" style={{width: '350px', height: '250px'}}>
                        <div>{t("customersOnlinelabel")}</div>
                            <div>
                                <h2>0</h2>
                            </div>
                        </Card>
                    </div>
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'}}>
                        <Link to={"/users-create"}><Button variant="contained" color="tertiary" size="large" >{t("newCustomerlabel")}</Button></Link>
                        <Link to={"/sheets-create"}><Button variant="contained" color="tertiary" size="large" style={{width: '200px'}}>{t("newSheetlabel")}</Button></Link>
                        <Link to={"/settings"}><Button variant="contained" color="tertiary" size="large" style={{width: '200px'}}>{t("profilelabel")}</Button></Link>
                    </div>
                </div>
        </div>
    );
    
};

export default DashBoard;