import React from 'react';
import './ContentInfo.css';
import prova1 from '../../assets/test1.jpg';
import prova2 from '../../assets/test2.jpg';

const ContentInfo = props => {
    return(
      <div className="roundDiv" style={{marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', justifyContent: 'center', color: 'white'}}>
        <div className="description" style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
              <div className="ContentDescription"> 
                <p>A STRONG WEB-APP FOR YOUR GYM.</p>
                <p>WITH BIG GYM YOU CAN TRACK YOUR</p>
                <p>CUSTOMERS TRAININGS AND MORE!</p>
              </div>
              <div><img src={prova1} /></div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
              <div><img src={prova1} /></div>
              <div className="ContentDescription"> 
                <p>STOP WASTING PAPER FOR GYM-SHEETS</p>
                <p>AN APP FOR IOS/ANDROID IS WHAT</p>
                <p>YOUR CUSTOMERS NEED AND YOU CAN</p>
                <p>TRACK THEIR RESULTS WITH THE WEB-APP</p>
              </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
              <div className="ContentDescription"> 
                <p>START WRITING YOUR CUSTOMER'S SHEETS</p>
                <p>WITH OUR INCREDIBLE TOOL</p>
                <p>IT IS REALLY SIMPLE AND YOU CAN SHARE</p>
                <p>THEM DIRECTLY TO THE CUSTOMER'S APP</p>
              </div>
              <div><img src={prova2} /></div>
          </div><br /><br /><br /><br /><br /><br />
        </div>
        
        
      </div>
    );
}
  
  export default ContentInfo
