import React, { useRef, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import gsap from 'gsap';
import instaLogo from '../../assets/instagram-logo.png'
import facebookLogo from '../../assets/facebook-logo.png'
import twitterLogo from '../../assets/twitter-logo.png'
import { useTranslation } from 'react-i18next'

const SidebarNav = props => {
  const { t, i18n } = useTranslation()
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  let sidebarMenu = useRef(null);
  let sidebarMenuOverlay = useRef(null);
  let menuLayer = useRef(null);
  const menuTimeline = useRef();

  const { menuState, setMenuState } = props;
  useEffect(() => {
    menuTimeline.current = gsap.timeline({ paused: true });
    menuTimeline.current.fromTo(
      [sidebarMenuOverlay, menuLayer, sidebarMenu],
      {
        duration: 0,
        x: '100%'
      },
      {
        duration: 0.75,
        x: '0%',
        ease: 'power3.inOut',
        stagger: {
          amount: 0.5
        }
      }
    );
  }, []);

  useEffect(() => {
    menuState ? menuTimeline.current.play() : menuTimeline.current.reverse();
  }, [menuState]);

  useEffect(() => {
    const { history } = props;
    history.listen(() => setMenuState(false));
  });

  return (
    <>
      <div
        className="sidebarNavigationOverlay"
        ref={el => (sidebarMenuOverlay = el)}
        onClick={() => setMenuState(false)}
      ></div>
      <div id="lateral-panel" className="menu-wrapper" style={menuState ? {zIndex: '8'} : {zIndex: '0'}}>
        <div className="menu-layer" ref={el => (menuLayer = el)}></div>
        <nav className="sidebarNavigation" ref={el => (sidebarMenu = el)}>
          <div className="sidebar-top">
            <div className="links-wrapper">
              <div style={{paddingTop: '10px', paddingLeft: '5px', paddingRight: '5px', color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <p>{t('chooseLanguage')}</p>
                <Button variant="outlined" color="secondary" 
                  onClick={() => changeLanguage("en")}>EN</Button>
                <Button variant="outlined" color="secondary" 
                  onClick={() => changeLanguage("it")}>IT</Button>
              </div>
              <hr style={{width: '100%'}} />
              <Link className="menu-link" to="/home">
                <div>{t('homelabel')}</div>
              </Link>
              <Link className="menu-link" to="/users">
                <div>{t('userslabel')}</div>
              </Link>
              <Link className="menu-link" to="/exercises">
                <div>{t('exerciseslabel')}</div>
              </Link>
              <Link className="menu-link" to="/sheets">
                <div>{t('sheetslabel')}</div>
              </Link>
              <Link className="menu-link" to="/chat">
                <div>{t('chatlabel')}</div>
              </Link>
              <Link className="menu-link" to="/settings">
                <div>{t('profilelabel')}</div>
              </Link>
              <Link className="menu-link" to="/">
                <div>{t('logOutlabel')}</div>
              </Link>
              <Link className="menu-link" to="/home">
                <div>PROMEMORIA</div>
              </Link>
            </div>
          </div>
          <div className="sidebar-bottom">
            <ul className="extra-links">
            <li className="link-item">
                <div className="link-title"><div>{t('gymlabel')}</div></div>
                <a href="">{localStorage.getItem("gymName")}, {localStorage.getItem("address")} </a>
              </li>
              <li className="link-item">
                <div className="link-title"><div>{t('supportEmaillabel')}</div></div>
                <a href="mailto:gusmeroligabriele22@gmail.com">gusmeroligabriele22@gmail.com</a>
              </li>
              <li className="link-item">
                <div className="link-title"><div>{t('socialMedialabel')}</div></div>
                <div className="social-media-links" style={{display: 'flex', justifyContent: 'center'}}>
                  <a href="https://www.instagram.com/gabriele_gusmeroli_/" target="_blank" className="social-link">
                    <img className="fab fa-instagram" src={instaLogo} style={{height: '32px', width: '32px'}} />
                  </a>
                  <a href="www.facebook.com" target="_blank" className="social-link">
                    <img className="fab fa-facebook-f" src={facebookLogo} style={{height: '32px', width: '32px'}}  />
                  </a>
                  <a href="www.twitter.com" target="_blank" className="social-link">
                    <img className="fab fa-twitter" src={twitterLogo} style={{height: '32px', width: '32px'}} />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};
export default withRouter(SidebarNav);