import React, { Component }  from 'react';
import { Button } from '@material-ui/core';
import {Card, TextField, Avatar} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

class SettingsPanel extends Component {
    
    constructor(props) {
        super(props);
    }

    state = {
        viewProfileComponent: true,
        editProfileComponent: false,
        viewManagerComponent: false,
    }

    toggleViewProfile = () => {
        this.setState({
            viewProfileComponent: true,
            editProfileComponent: false,
            viewManagerComponent: false
        })
    }

    toggleEditProfile = () => {
        this.setState({
            editProfileComponent: true,
            viewProfileComponent: false,
            viewManagerComponent: false
        })
    }

    toggleViewManager = () => {
        this.setState({
            viewManagerComponent: true,
            editProfileComponent: false,
            viewProfileComponent: false
        })
    }

    render(){
        return (
            <div style={{width: '100%', height: '100%',display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Card style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
                    <div style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{marginRight: '60px', marginLeft: '10px', width: '100%',display: 'flex', flexDirection: 'column'}}>
                            <div style={{textAlign: 'left'}}><h1>PROFILE</h1></div>
                            <div style={{border: '1px solid black', width: '125%'}}></div>
                            <div style={{width: '120%',display: 'flex', flexDirection: 'column', paddingTop: '20px'}}>
                                <Button size="small" variant="outlined" color="primary" style={{textAlign: 'left'}} onClick={this.toggleViewProfile}><h3>Visualizza Profilo</h3></Button>
                                <Button size="small" variant="outlined" color="primary" style={{textAlign: 'left', top: '5px'}} onClick={this.toggleEditProfile}><h3>Modifica Profilo</h3></Button>
                                <Button size="small" variant="outlined" color="primary" style={{textAlign: 'left', top: '10px'}} onClick={this.toggleViewManager}><h3>Visualizza proprietario</h3></Button>                    
                            </div>
                        </div>
                        <div style={{borderLeft: '2px solid black', height: '100%', width: '2px'}}>
                        </div>
                    </div>
                    <div>
                        {this.state.viewProfileComponent && (
                            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{paddingLeft: '20%', paddingTop: '24%', paddingRight: '20%'}}><Avatar style={{width: '150px', height: '150px'}}>GG</Avatar></div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <div style={{borderLeft: '1px solid black', height: '60%', width: '2px'}} />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '24%', alignItems: 'center', paddingLeft: '20%'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                        <TextField
                                            disabled
                                            label="Nome"
                                            style={{ margin: 8, width: '220px' }}
                                            defaultValue="Gabriele"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            />
                                        <TextField
                                            disabled
                                            label="Cognome"
                                            style={{ margin: 8, width: '220px' }}
                                            defaultValue="Gusmeroli"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            />
                                        <TextField
                                            disabled
                                            label="Username"
                                            style={{ margin: 8, width: '220px' }}
                                            defaultValue="Insan3"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                        <TextField
                                            disabled
                                            label="Email"
                                            style={{ margin: 8, width: '692px' }}
                                            defaultValue="g.gabry22@hotmail.it"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            />
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.editProfileComponent && (
                            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
                            <div style={{paddingLeft: '20%', paddingTop: '24%', paddingRight: '20%'}}><Avatar style={{width: '150px', height: '150px'}}>GG</Avatar></div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{borderLeft: '1px solid black', height: '60%', width: '2px'}} />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '24%', alignItems: 'center', paddingLeft: '20%'}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <TextField
                                        label="Nome"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue="Gabriele"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        />
                                    <TextField
                                        label="Cognome"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue="Gusmeroli"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                    <TextField
                                        label="Username"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue="Insan3"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <TextField
                                        label="Email"
                                        style={{ margin: 8, width: '692px' }}
                                        defaultValue="g.gabry22@hotmail.it"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <TextField
                                        label="Vecchia password"
                                        type="password"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue=""
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                    <TextField
                                        label="Nuova password"
                                        type="password"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue=""
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                    <TextField
                                        label="Ripeti Nuova password"
                                        type="password"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue=""
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <Button size="small" variant="outlined" color="primary" style={{textAlign: 'left', height: '40px'}} onClick={null}><h3>Salva</h3></Button>
                                </div>
                            </div>
                        </div>
                        )}

                        {this.state.viewManagerComponent && (
                            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
                            <div style={{paddingLeft: '20%', paddingTop: '24%', paddingRight: '20%'}}><Avatar style={{width: '150px', height: '150px'}}>GG</Avatar></div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{borderLeft: '1px solid black', height: '60%', width: '2px'}} />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '24%', alignItems: 'center', paddingLeft: '20%'}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <TextField
                                        disabled
                                        label="Nome"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue="Gabriele"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        />
                                    <TextField
                                        disabled
                                        label="Cognome"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue="Gusmeroli"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                    <TextField
                                        disabled
                                        label="Username"
                                        style={{ margin: 8, width: '220px' }}
                                        defaultValue="Insan3"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <TextField
                                        disabled
                                        label="Email"
                                        style={{ margin: 8, width: '692px' }}
                                        defaultValue="g.gabry22@hotmail.it"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </Card>
            </div>
        );
    }
};

export default SettingsPanel;