import React from 'react';
import { withRouter } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import './RegisterCard.css';


class RegisterCard extends React.Component {
    constructor(props){
        super(props);
        this.state={
        username:'',
        email:'',
        checkEmail:'',
        name:'',
        surname:'',
        password:'',
        checkPassword:''
        }
    }

    render(){
        return(
        <div className="roundDiv" style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div className="registerDiv" style={{height: '80%', display: 'flex', flexDirection: 'column',margin: 'auto'}}>
                <div className="descri">
                <li><h1>BIG GYM</h1></li>
                <br/>
                <li>Register</li>
                <div>
                    <MuiThemeProvider>
                    <div className="objects">
                        <p>
                            <TextField
                                hintText="Enter your Name"
                                floatingLabelText="Name"
                                value={this.state.name}
                                onChange = {(event,newValue) => this.setState({name:newValue})}
                                />
                        </p>
                        <p>
                            <TextField
                            hintText="Enter your Surname"
                            floatingLabelText="Surname"
                            onChange = {(event,newValue) => this.setState({surname:newValue})}
                                />
                        </p>
                        <p>
                            <TextField
                            hintText="Enter your Username"
                            floatingLabelText="Username"
                            onChange = {(event,newValue) => this.setState({username:newValue})}
                                />
                        </p>
                        <br/>
                        <p>
                            <TextField
                                hintText="Enter your Email"
                                floatingLabelText="Email"
                                onChange = {(event,newValue) => this.setState({email:newValue})}
                                />
                        </p>
                        <p>
                            <TextField
                            hintText="Re-enter your Email"
                            floatingLabelText="Check Email"
                            onChange = {(event,newValue) => this.setState({checkEmail:newValue})}
                                />
                        </p>
                        <br/>
                        <p>
                            <TextField
                                type="password"
                                hintText="Enter your Password"
                                floatingLabelText="Password"
                                onChange = {(event,newValue) => this.setState({password:newValue})}
                                />
                        </p>                        
                        <p>
                            <TextField
                                type="password"
                                hintText="Re-enter your Password"
                                floatingLabelText="Check Password"
                                onChange = {(event,newValue) => this.setState({checkPassword:newValue})}
                                />
                        </p>
                        <br/>
                        <RaisedButton label="Sign up" primary={true} style={style} onClick={this.UserRegistrationFunction}/>
                    </div>
                    </MuiThemeProvider>
                </div>
                </div>
            </div>
        </div>
        );
    }
    UserRegistrationFunction = (props) =>{
        if(this.checkFields()){
            fetch('http://gusmeroligabriele.altervista.org/db/user_registration.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                surname: this.state.surname,
                email: this.state.email,
                password: this.state.password,
                username: this.state.username,
            })
            
            }).then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson[0] == "ok"){
                        localStorage.setItem("username", this.state.username);
                        localStorage.setItem("idOwn", responseJson[1]);
                        this.props.history.push({
                            pathname: '/registergym',
                            state: ""
                        });   
                    } else if(responseJson[0] == "error1"){
                        alert("Registered email, check if it's correct!");
                    }else if(responseJson[0] == "error2"){
                        alert("Username already taken!");
                    }else if(responseJson[0] == "error3"){
                        alert("Username already taken!\nRegistered email, check if it's correct!");
                    }          
                }).catch((error) => {
                    console.error(error);
                });
        
        }
    }

    checkFields = (props) =>{
        let err = 0;
        var errore = "Miss: ";
        if(this.state.name == ""){
            err--;
            errore = errore + "Name ";
        }
        if(this.state.surname == ""){
            err--;
            errore = errore + "Surname ";
        }
        if(this.state.username == ""){
            err--;
            errore = errore + "Username  ";
        }
        if(this.state.email == ""){
            err--;
            errore = errore + "Email ";
        }
        if(this.state.password == ""){
            err--;
            errore = errore + "Password  ";
        }
        if(this.state.email != this.state.checkEmail){
            err--;
            errore = errore + "\nError differents mails ";
        }
        if(this.state.password != this.state.checkPassword){
            err--;
            errore = errore + "\nError differents passwords ";
        }
        if(err != 0){
            alert(errore);
            return false;
        }
        return true;
    }

}



const style = {
    margin: 20,
   };  

export default withRouter(RegisterCard);
