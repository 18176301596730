import React, {useState} from "react";
import "./HomePage.css";
import Header from '../../Components/Panel/Header';
import SidebarNav from '../../Components/Panel/Panel';

const HomePage = (props) => {
let [menuState, setMenuState] = useState(false);
    return (
        <div style={{width: '100%', height: '100%', position: 'fixed', display: 'flex', flexDirection: 'column'}}>
            <div className="App container">
                <Header menuState={menuState} setMenuState={setMenuState} />
                <SidebarNav menuState={menuState} setMenuState={setMenuState} />
            </div>
            <div id='particles' style={{top: '0', left: '0', width: '100%', height: '100%', position: 'fixed', zIndex: '4'}}>
                <div style={{backgroundColor: '#151719', top: '50px', left: '0', width: '100%', height: '100%', position: 'fixed' ,display: 'flex', flexDirection: 'column', textAlign: 'center'}} className="homeChildren" >
                    {props.children}
                </div>
            </div>
        </div>  
    );
}

export default HomePage