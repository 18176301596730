import React from "react";
import "./styles.css";
import './style.scss'

import About from "./About";
import { Route, Switch } from "react-router-dom";
import Jobs from "./Jobs";
import Engineer from "./Engineer";
import Marketer from "./Marketer";
import Designer from "./Designer";
import Breadcrumbs from "./Breadcrumbs";
import Snackbar from "./Snackbar";
import {MainPage} from './Views/MainPage/MainPage' 
import ContentInfo from './Components/ContentInfo/ContentInfo'
import LoginCard from './Components/LoginCard/LoginCard'
import ContactsCard from './Components/ContactsCard/ContactsCard'
import RegisterCard from './Components/RegisterCard/RegisterCard'
import HomePage from './Views/HomePage/HomePage'
import DashBoard from './Components/DashBoard/DashBoard'
import RegisterGym from './RegisterGym/RegisterGym'
import SettingsPanel from './SettingsPanel'
import SettingsDelete from './SettingsDelete'
import ExercisePanel from './Views/Exercises/ExercisesPanel'
import UserPanel from './Views/Users/UserPanel'
import SheetPanel from './Views/Sheets/SheetPanel'

export default function App() {
  return (
    <>
      <Breadcrumbs />
      <Snackbar />
      <Switch>
        {/* <Route exact from="/" render={props => <Home {...props} />} /> */}
        <Route exact path="/about" render={props => <About {...props} />} />
        <Route exact path="/jobs" render={props => <Jobs {...props} />} />
        <Route exact path={"/"} render={(props) =>
            <div>
              <MainPage>
                <ContentInfo />
              </MainPage>
            </div>
          } />
          <Route exact path={"/login"} render={(props) =>
            <div>
              <MainPage>
                <LoginCard />
              </MainPage>
            </div>
          } />
          <Route exact path={"/contacts"} render={(props) =>
            <div>
              <MainPage>
                <ContactsCard />
              </MainPage>
            </div>
          } />
          <Route exact path={"/register"} render={(props) =>
            <div>
              <MainPage>
                <RegisterCard />
              </MainPage>
            </div>
          } /><Route exact path={"/registergym"} render={(props) =>
            <div>
              <MainPage>
                <RegisterGym />
              </MainPage>
            </div>
          } />
          <Route exact path={"/home"} render={(props) =>
            <div>
              <HomePage>
                <div style={{width: '100%', height: '100%'}}>
                  <DashBoard /> 
                </div>
              </HomePage>
            </div>
          } />
          <Route exact path={"/users"} render={(props) =>
            <div>
              <HomePage>
                <UserPanel />
              </HomePage>
            </div>
          } />
          <Route exact path={"/exercises"} render={(props) =>
            <div>
              <HomePage>
                <ExercisePanel />
              </HomePage>
            </div>
          } />
          <Route exact path={"/chat"} render={(props) =>
            <div>
              <HomePage>
                
              </HomePage>
            </div>
          } />
          <Route exact path={"/sheets"} render={(props) =>
            <div>
              <HomePage>
                <SheetPanel />
              </HomePage>
            </div>
          } />
          <Route exact path={"/sheets-modify"} render={(props) =>
            <div>
              <HomePage>
                <SheetPanel />
              </HomePage>
            </div>
          } />
          <Route exact path={"/sheets-delete"} render={(props) =>
            <div>
              <HomePage>
                <SheetPanel />
              </HomePage>
            </div>
          } />
          <Route exact path={"/settings"} render={(props) =>
            <div>
              <HomePage>
                <SettingsPanel />
              </HomePage>
            </div>
          } />
          <Route exact path={"/settings-delete"} render={(props) =>
            <div>
              <HomePage>
                <SettingsDelete />
              </HomePage>
            </div>
          } />
      </Switch>
    </>
  );
}
